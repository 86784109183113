import {
  browserApiErrorsIntegration,
  browserProfilingIntegration, browserTracingIntegration,
  captureConsoleIntegration,
  contextLinesIntegration,
  extraErrorDataIntegration, feedbackIntegration,
  handleErrorWithSentry, init, replayCanvasIntegration,
  replayIntegration
} from '@sentry/sveltekit';
try {

  init({
    dsn: 'https://02de4e5814b038bcfacc1761b983952a@o4506610441519104.ingest.sentry.io/4506610443550720',
    tracesSampleRate: 1.0,
  
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
  
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
  
    // If you don't want to use Session Replay, just remove the line below:
    integrations: [
      browserProfilingIntegration(),
      browserTracingIntegration(),
      browserApiErrorsIntegration(),
      captureConsoleIntegration(),
      contextLinesIntegration(),
      extraErrorDataIntegration(),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
      replayCanvasIntegration(),
      feedbackIntegration({
        colorScheme: "system",
        triggerLabel: "Send tilbakemelding",
        formTitle: "Send tilbakemelding",
        submitButtonLabel: "Send",
        cancelButtonLabel: "Avbryt",
        confirmButtonLabel: "Bekreft",
        addScreenshotButtonLabel: "Legg til skjermbilde",
        removeScreenshotButtonLabel: "Fjern skjermbilde",
        nameLabel: "Navn",
        namePlaceholder: "Skriv inn navnet ditt",
        emailLabel: "E-post",
        emailPlaceholder: "Skriv inn e-postadressen din",
        isRequiredLabel: "(obligatorisk)",
        messageLabel: "Melding",
        messagePlaceholder: "Skriv inn meldingen din",
        successMessageText: "Takk for tilbakemeldingen!",
        showBranding: false,
      }),
    ],
    debug: false,
  });
} catch (e) {
 // eslint-disable-next-line no-console
}


// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
